import React from "react"
import styled from "@emotion/styled"
import systemCss from "@styled-system/css"
import { navigate } from "gatsby"

// Import assets
import DepthInfinityElement1 from "../../../assets/images/illustrations/depth-infinity-element1.svg"
import DepthInfinityElement3 from "../../../assets/images/illustrations/depth-infinity-element3.svg"
import LostIllustrationImg from "../../../assets/images/illustrations/tenxgeeks-LostIllustration.svg"
import AtSymbolIcon from "../../../assets/images/icons/tenxgeeks-at-symbol.svg"
import HomeIcon from "../../../assets/images/icons/tenxgeeks-home-icon.svg"

import { RoundButton } from "../../atoms/Button"

import { sm, md, lg } from "../../../utils/mediaQueries"

const LostSection = styled.div`
  display: block;
  width: 100%;
  overflow: hidden;

  background-image: url(${DepthInfinityElement3}), url(${DepthInfinityElement1});
  background-position: left 10% top 25%, right 10% bottom 25%;
  background-size: 12rem auto, 12rem auto;
  background-repeat: no-repeat, no-repeat;

  ${sm} {
    background-position: left -3rem top 50%, right -3rem bottom 25%;
    background-size: 8rem auto, 8rem auto;
  }
`

const LostContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 1440px;
  margin: 0px auto;
  padding: 8rem 7rem 10rem;

  ${lg} {
    padding: 6rem 7rem 8rem;
  }

  ${sm} {
    padding: 2.4rem 2rem 4rem;
  }
`

const LostIllustration = styled.div`
  display: block;

  & > img {
    display: block;
    max-height: 42rem;
    max-width: 100%;
  }
`

const LostContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4rem;

  ${sm} {
    margin-top: 2.4rem;
  }
`

const SectionHeading = styled.h2`
  font-weight: 700;
  line-height: 1.25;
  text-align: center;

  ${systemCss({
    color: "black",
    fontFamily: "primary",
    fontSize: ["h4", "h3", "h2"]
  })}
`

const SectionSubheading = styled.h4`
  margin-top: 2.5rem;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;

  ${systemCss({
    color: "black",
    fontFamily: "primary",
    fontSize: ["p", "h5", "h5", "h4"]
  })}

  ${sm} {
    margin-top: 1rem;
  }
`

const CTASection = styled.div`
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CTAButton = styled.a`
  display: flex;
  outline: none;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  padding: 1rem 3rem;
  height: 8rem;
  border-radius: 6rem;

  ${systemCss({
    bg: "purple"
  })}

  & > span {
    font-weight: 700;
    ${systemCss({
      fontSize: ["link", "h5"],
      fontFamily: "primary",
      color: "white"
    })}
  }

  & > img {
    height: 4.2rem;
    width: auto;
    padding-right: 1.6rem;
  }

  ${sm} {
    height: 5.6rem;
    padding: 1rem 2rem;

    & > img {
      height: 3rem;
      padding-right: 0.8rem;
    }
  }
`

const HomeButton = styled(RoundButton)`
  margin-right: 4rem;
  ${sm} {
    margin-right: 1.6rem;
  }
`

export default props => {
  // Navigate to home screen
  const navigateToHome = () => navigate("/")
  return (
    <LostSection>
      <LostContainer>
        <LostIllustration>
          <img src={LostIllustrationImg} alt="404 page illustration" />
        </LostIllustration>
        <LostContent>
          <SectionHeading>Looks like you got lost</SectionHeading>
          <SectionSubheading>
            The page you’re looking for doesn’t exist.
          </SectionSubheading>
          <CTASection>
            <HomeButton type="button" bg="yellow" onClick={navigateToHome}>
              <img src={HomeIcon} alt="home icon" />
            </HomeButton>
            <CTAButton href="mailto:support@tenxgeeks.com">
              <img src={AtSymbolIcon} alt="at symbol icon" />
              <span>Send Us An Email</span>
            </CTAButton>
          </CTASection>
        </LostContent>
      </LostContainer>
    </LostSection>
  )
}
